// Here you can add other styles
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.fixo-footer {
	height: 50px;
	position: fixed;
	bottom: 0px;
	width: 100%;
	opacity: 1;
	/*background: #e4e5e6;*/
  left: 0;
  z-index: 9999;
}
.fixo-footer-clear{
	height: 70px;
}
.bt-center{
	margin: 8px auto;
  position: relative;
}
.bt-lg-salvar{
	width: 200px;
}
.align-center-custom{
	position: relative;
    top: 60px;
}
.main > div > div > div{
	border-bottom: none!important;
}
.spaceTab{
	display: block;overflow: hidden;height: 3px;background: white;
}
ul[role=tablist] li{
	margin-left: 2px;
	margin-right: 1px;
	border-color: #d9d9d9;
}
body {
	font-size: 1.0rem!important;
}
.is-invalid .kn-multi_select__wrapper___30BEc{
	border-color: red !important;
}
.is-invalid-multipleselect{
	width: 100%;
    margin-top: 0.25rem;
    font-size: 90%;
    color: #f86c6b;
}
label{margin-bottom: 0.2rem;}
.header-fixed .app-header{z-index: 100;}

.align-button{
	position: relative;
    top: 26px;
}

//  css radio button
.container-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.container-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

.container-radio input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-radio input:checked ~ .checkmark:after {
  display: block;
}

.container-radio .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
//  fim css radio button fim

.card-header-white{
	background-color: #f0f3f200
}

.valor-fatura .valor{
  font-size: 90px;
}

.valor-fatura .label-fatura{
  margin-bottom: 10px;
}

.bloquear{
  /*display: inline-block;*/
  pointer-events: none;
  opacity: 0.7;
}
.desbloquear{
  /*display: inline-block;*/
  pointer-events: all !important;
  opacity: 1 !important;
}
.message-cancelado{
  color: red;
  font-size:12px
}
.message-pago{
  color: #4dbd74;
  font-size:12px
}
/*
.footer-pedido{
  margin: 0px 650px;
}
.footer-pedido-div{
  margin: 0px 41px;
}
*/

.notification{
  position: absolute;
  will-change:
  transform;
  top: 25px !important;
  left: -30px !important;
  transform: translate3d(-144px, -12px, 0px);
}
.pointer{
  cursor: pointer
}


.invalid-feedback{margin-top: 0!important;margin-bottom: 5px;}
.form-group{margin-bottom: 0!important;}
.form-control{margin-bottom: 0!important;}
.form-control:not(.is-invalid){margin-bottom: 24px!important;}
.ok-cep, .input-group-append, .input-group-text,.input-group-prepend {height: 35px !important;}
.block-space{display: block;overflow: hidden;width: 100%;height: 20px;}
.width-19{width: 19%;}

.item-img{
  width: '60px'; height: '100px';
}
.with-field-cj{width: 75%;}
.with-btn-cj{width: 25%;}
.sku-item{color: #666666;font-size: 13px;}
.nowrap{white-space: nowrap;}
.wqtd{width: 70px!important;}
.help-label{font-size: 13px;color:#666666;}
.sidebar .nav-title {padding: 1.50rem 0 0.15rem 0;color: #aaaaaa;border-bottom: solid 1px #444;margin: 0 14px;}
.sidebar .nav-link{padding: 0.25rem 1rem;}


.kendo-pdf {
  font-family: "DejaVu Sans", "Arial", sans-serif;
  font-size: 27px;
}

/*
  The example loads the DejaVu Sans from the Kendo UI CDN.
  Other fonts have to be hosted from your application.
  The official site of the Deja Vu Fonts project is
  https://dejavu-fonts.github.io/.
*/
@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

.bt-center-pedido{
  /*left: -195px;*/
  margin: 8px auto;
  position: relative;
}
.img-capa{max-width: 100%;}
.user-logged{float: left;}
.tc{text-align: center;}
.tl{text-align: left;}
.separate-btn{display: inline-block;width: 25px;}

.format-cell{
  width: 300px !important;
}

.footer-row{
  display: flex;
  flex-wrap: wrap;
}
.footer-col-1{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #212529;
  width: 200px;
  height: 50px;
}
.footer-col-2{
  display: block;
  box-sizing: border-box;
  flex: 1 1;
  background: #e4e5e6;
  text-align: center;
  height: 50px;
}
.inl{display: inline-block;}
.p-sku{float:left;padding: 3px 4px;margin: 1px 4px;font-size: 0.85rem;color:#f86c6b;background: #f0f3f5;border-radius: 0.3rem;}
table.itens-faturar thead tr th{font-size: 13px; padding: 0 5px;}
table.itens-faturar tbody tr td{font-size: 14px; padding: 3px 5px;}
.nenhum-item{font-size: 18px;text-align: center;background: #e3e3e3;color:red;}
.thead-default{background: #c8ced3;}
.codigo-pedido{font-size: 20px;}
.status-pedido{float: right;font-size: 19px;}
.margin-label{
  margin-right: 7px;
}
.margin-left-span{
  margin-left: 25px;
}

.width-price{
  width:150px !important;
}

.label-button-navigate{
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  background: #20a8d8;
  border-color: #20a8d8;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  font-size: 0.875rem;
  display: inline-block;
  //font-weight: 400;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
}

.label-button-navigate:hover{
  background-color: #43b6d7;
  border-color: #39b2d5;
  text-decoration: none;
}

.label-button-upload{
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  background: #4dbd74;
  border-color: #4dbd74;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  font-size: 0.875rem;
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
}

.label-button-upload:hover{
  background-color: #3ea662;
  border-color: #3a9d5d;
  text-decoration: none;
}

.label-message{
  color: #FF0000;
}
